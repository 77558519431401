import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { systemTheme } from "./theme/themes";
import "./scss/style.scss";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "bootstrap/dist/css/bootstrap.min.css";

const Home = React.lazy(() => import("./views/home/Home"));
const SeatLayout = React.lazy(() => import("./views/seat-layout/SeatLayout"));
const GetFood = React.lazy(() => import("./views/get-food/GetFood"));
const FoodOnCinema = React.lazy(() =>
  import("./views/food-on-cinema/FoodOnCinema")
);
const FoodOnSeat = React.lazy(() => import("./views/food-on-seat/FoodsOnSeat"));
const CinemasSession = React.lazy(() =>
  import("./views/cinemas/cinemaList/CinemasSession")
);
const MovieSession = React.lazy(() =>
  import("./views/movie-sessions/MovieSessions")
);
const Cinemas = React.lazy(() => import("./views/cinemas/Cinemas"));
const Offers = React.lazy(() => import("./views/offers/Offers"));
const OfferDetails = React.lazy(() => import("./views/offers/OfferDetails"));
const Advertise = React.lazy(() =>
  import("./views/static-pages/advertise/Advertise")
);
const AboutUs = React.lazy(() =>
  import("./views/static-pages/about-us/AboutUs")
);
const CareerPage = React.lazy(()=> import("./views/static-pages/career/Career"))
const FeedbackPage = React.lazy(() =>
  import("./views/static-pages/feedback/Feedback")
);
const Faq = React.lazy(() => import("./views/static-pages/faq/Faq"));
const PaymentPage = React.lazy(() => import("./views/payment/PaymentPage"));
// const MovieCard = React.lazy(() => import("./views/movie-card/MovieCard"));
const PrivateScreening = React.lazy(() => import("./views/static-pages/Private"));
const CorporateBooking = React.lazy(() => import("./views/static-pages/Private"));
const SchoolBooking = React.lazy(() => import("./views/static-pages/Private"));
const PrivacyPolicy = React.lazy(() =>
  import("./views/static-pages/privacy-policy/PrivacyPolicy")
);
const Termsconditions = React.lazy(() =>
  import("./views/static-pages/terms-conditions/TermsConditions")
);
const Profile = React.lazy(() => import("./views/profile/Profile"));
const Ticket = React.lazy(() => import("./views/final-ticket/FinalTicket"));
const MovieCardTicket = React.lazy(() =>
  import("./views/final-ticket/MovieCardTicket")
);
const FoodTicket = React.lazy(() => import("./views/final-ticket/FoodTicket"));
const PaymentCheck = React.lazy(() =>
  import("./views/payment/payment-check/PaymentCheck")
);

const CancelTransUI = React.lazy(() =>
  import("./views/payment/cancel-trans/CancelTrans")
);

const PageNotFound = React.lazy(() =>
  import("./views/page-not-found/PageNotFound")
);

function App() {
  const themeVal = "systemTheme";
  localStorage.setItem("theme", themeVal);
  const theme = systemTheme;
  const isMobile = window.matchMedia("(max-width: 575px)").matches;

  const loading = (
    <div
      className="pt-3 d-flex justify-content-center align-items-center bg-theme-light2"
      style={{ height: "100vh" }}
    >
      <div>
        <i
          className="pi pi-spin pi-spinner text-theme-dark1"
          style={{ fontSize: "5em" }}
        ></i>
      </div>
    </div>
  );

  if (!localStorage.getItem("lat")) {
    localStorage.setItem("lat", "0.000");
    localStorage.setItem("long", "0.000");
  }
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Suspense fallback={loading}>
          <Router>
            <Routes>
              <Route path="/" name="Home" element={<Home />} />
              <Route path="/home" name="Home" element={<Home />} />
              <Route
                path="/moviesessions/:city/:moviename/:mid"
                name="Movies Session"
                element={<MovieSession />}
              />
              <Route
                path="/cinemas/:city"
                name="Cinemas"
                element={<Cinemas />}
              />
              <Route
                path="/cinemasessions/:city/:cname/:ccode"
                name="Cinemas"
                element={<CinemasSession />}
              />
              <Route path="/offers" name="Offers" element={<Offers />} />
              <Route
                path="/offer-details/:offerId/:cityName"
                name="Offer Details"
                element={<OfferDetails />}
              />
              <Route
                path="/seatlayout/:cinema_name/:movie_name"
                name="Seat Layout"
                element={<SeatLayout />}
              />
              <Route
                path="/food/:city/:cinemaname/:cinemaId"
                element={<GetFood />}
              />
              <Route
                path="/food/:city/qr/:cinemaId" name='Food on Cinema'
                element={isMobile ? <FoodOnCinema /> : <PageNotFound />}
              />
              <Route
                path="/foods/:cinemaId/:audiNo/:seatNo" name='Food on Seat'
                element={isMobile ? <FoodOnSeat />: <PageNotFound />}
              />
              {/* <Route path="/movie-cards" element={<MovieCard />} /> */}
              <Route path="/payment/:cinemaname" element={<PaymentPage />} />
              <Route path="/movie/booking/razorpayex" element={<PaymentCheck />} />
              <Route path="/cancel/trans" element={<CancelTransUI />} />
              <Route
                path="/private-screening"
                name="Private Screening"
                element={<PrivateScreening />}
              />
               <Route
                path="/corporate-booking"
                name="corporate Booking"
                element={<CorporateBooking />}
              />
               <Route
                path="/school-booking"
                name="School Booking"
                element={<SchoolBooking />}
              />
              <Route path="/about-us" name="About Us" element={<AboutUs />} />
              <Route path="/careers" name="Careers" element={<CareerPage />} />
              <Route
                path="/contact-us"
                name="Feedback"
                element={<FeedbackPage />}
              />
              <Route path="/faq" name="faq" element={<Faq />} />
              <Route
                path="/advertise"
                name="Advertise"
                element={<Advertise />}
              />
              <Route
                path="/privacy-policy"
                name="privacy-policy"
                element={<PrivacyPolicy />}
              />
              <Route
                path="/terms-conditions"
                name="terms-conditions"
                element={<Termsconditions />}
              />
              <Route path="/profile" name="Profile" element={<Profile />} />
              <Route path="/booking" name="Movie Ticket" element={<Ticket />} />
              <Route
                path="/moviecard-ticket"
                name="Movie Card Ticket"
                element={<MovieCardTicket />}
              />
              <Route
                path="/food-ticket"
                name="Food Ticket"
                element={<FoodTicket />}
              />
              <Route
                path="*"
                name="Page not found"
                element={<PageNotFound />}
              />
            </Routes>
          </Router>
        </Suspense>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
